import React, { useState } from "react"

import imageCos from "../../../images/triangles/cos.png"
import imageSin from "../../../images/triangles/sin.png"
import imageSin1 from "../../../images/triangles/sin1.png"
import geometricTriangle04 from "../../../images/triangles/geometric-triangle-04.png"
import geometricTriangle05 from "../../../images/triangles/geometric-triangle-05.png"
import geometricTriangle01 from "../../../images/triangles/geometric-triangle-01.png"
import rectangular from "../../../images/triangles/rectangular.png"
import acuteangle from "../../../images/triangles/acuteangle.png"
import obtuse from "../../../images/triangles/obtuse.png"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  StaticButton,
  Control,
  Field,
  Input,
  Columns,
  Column,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Triangle calculation",
    link: "/triangle-calculation/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function TriangleCalculation(props) {
  let [a, seta] = useState("")
  let [b, setb] = useState("")
  let [c, setc] = useState("")
  let [aCos, setaCos] = useState("")
  let [bCos, setbCos] = useState("")
  let [xCos, setxCos] = useState("")
  let [aSin1, setaSin1] = useState("")
  let [bSin1, setbSin1] = useState("")
  let [xbSin1, setxbSin1] = useState("")
  let [aSin2, setaSin2] = useState("")
  let [xSin2, setxSin2] = useState("")
  let [bSin2, setbSin2] = useState("")

  let [imageTopPage, setimageTopPage] = useState(imageCos)
  let [imageresultCos, setimageresultCos] = useState("")
  let [resultImageSin1, setresultImageSin1] = useState("")
  let [resultImageSin2, setresultImageSin2] = useState("")

  let [resultsDivP, setresultsDivP] = useState("")
  let [resultCos, setresultCos] = useState("")
  let [resultSin1, setresultSin1] = useState("")
  let [resultSin2, setresultSin2] = useState("")

  function aChange(e) {
    seta(e.target.value)
  }
  function bChange(e) {
    setb(e.target.value)
  }
  function cChange(e) {
    setc(e.target.value)
  }
  function aCosChange(e) {
    setaCos(e.target.value)
  }
  function bCosChange(e) {
    setbCos(e.target.value)
  }
  function xCosChange(e) {
    setxCos(e.target.value)
  }
  function aSin1Change(e) {
    setaSin1(e.target.value)
  }
  function bSin1Change(e) {
    setbSin1(e.target.value)
  }
  function xbSin1Change(e) {
    setxbSin1(e.target.value)
  }
  function aSin2Change(e) {
    setaSin2(e.target.value)
  }
  function xSin2Change(e) {
    setxSin2(e.target.value)
  }
  function bSin2Change(e) {
    setbSin2(e.target.value)
  }

  function run(e) {
    e.preventDefault()
    var values = [a, b, c]

    var triangleImages = {
      isosceles: geometricTriangle04,
      equilateral: geometricTriangle05,
      versatile: geometricTriangle01,
      rectangular: rectangular,
      acteangle: acuteangle,
      obtuse: obtuse,
    }

    var triangleCaptions = {
      isosceles: "isosceles",
      equilateral: "equilateral",
      versatile: "versatile",
      rectangular: "rectangular",
      acteangle: "acteangle",
      obtuse: "obtuse",
    }

    setresultsDivP("")
    var errorMessage = validate(values)
    if (errorMessage) {
      setresultsDivP(errorMessage)
      return
    }

    values = values.map(Number)

    if (isNotExists(values)) {
      setresultsDivP("Such a triangle does not exist.")
    } else {
      var triangle = calculate(values)
      var image = triangleImages[triangle.type]
      var caption = triangleCaptions[triangle.type]
      setresultsDivP(
        <div>
          s = {triangle.s} , Triangle {caption}
        </div>
      )
      setimageTopPage(image)
    }
  }

  function runCos(e) {
    e.preventDefault()
    var valuesCos = [aCos, bCos, xCos]

    var triangleImages = {
      isosceles: geometricTriangle04,
      equilateral: geometricTriangle05,
      versatile: geometricTriangle01,
      rectangular: rectangular,
      acteangle: acuteangle,
      obtuse: obtuse,
    }

    var triangleCaptions = {
      isosceles: "isosceles",
      equilateral: "equilateral",
      versatile: "versatile",
      rectangular: "rectangular",
      acteangle: "acteangle",
      obtuse: "obtuse",
    }

    setresultCos("")
    valuesCos = valuesCos.map(Number)
    var triangleCos = calculateSquareCos(valuesCos)
    var imageCos = triangleImages[triangleCos.typeCos]
    var captionCos = triangleCaptions[triangleCos.typeCos]
    setresultCos(
      "s = " +
        triangleCos.squareCos +
        "; " +
        "Sides of the triangle: а = " +
        valuesCos[1] +
        "; " +
        "b = " +
        valuesCos[2] +
        "; " +
        "c = " +
        valuesCos[0] +
        "; " +
        "Triangle angles in degrees: α = " +
        triangleCos.alphaCos +
        "; " +
        "β = " +
        triangleCos.bettaCos +
        "; " +
        "γ = " +
        valuesCos[3] +
        "; " +
        "Triangle " +
        captionCos +
        "; "
    )
    setimageresultCos(imageCos)
  }

  function runSinSide(e) {
    e.preventDefault()
    var valuesSinSide = [aSin1, bSin1, xbSin1]

    var triangleImages = {
      isosceles: geometricTriangle04,
      equilateral: geometricTriangle05,
      versatile: geometricTriangle01,
      rectangular: rectangular,
      acteangle: acuteangle,
      obtuse: obtuse,
    }

    var triangleCaptions = {
      isosceles: "isosceles",
      equilateral: "equilateral",
      versatile: "versatile",
      rectangular: "rectangular",
      acteangle: "acteangle",
      obtuse: "obtuse",
    }

    setresultSin1("")

    valuesSinSide = valuesSinSide.map(Number)

    var angleSinSide = calculateSquareSinSide(valuesSinSide)
    var imageSinSide = triangleImages[angleSinSide.type]
    var captionSinSide = triangleCaptions[angleSinSide.type]
    setresultSin1(
      "s = " +
        angleSinSide.squareSinSide +
        "; " +
        "Sides of the triangle: а = " +
        valuesSinSide[0] +
        "; " +
        "b = " +
        valuesSinSide[1] +
        "; " +
        "c = " +
        angleSinSide.cSinSide +
        "; " +
        "Triangle angles in degrees: α = " +
        angleSinSide.alphaSinSide +
        "; " +
        "β = " +
        valuesSinSide[2] +
        "; " +
        "γ = " +
        angleSinSide.gammaSinSide +
        "; " +
        "Triangle " +
        captionSinSide
    )
    setresultImageSin1(imageSinSide)
  }

  function runSinAngle(e) {
    e.preventDefault()
    var valuesSinAngle = [aSin2, xSin2, bSin2]

    var triangleImages = {
      isosceles: geometricTriangle04,
      equilateral: geometricTriangle05,
      versatile: geometricTriangle01,
      rectangular: rectangular,
      acteangle: acuteangle,
      obtuse: obtuse,
    }

    var triangleCaptions = {
      isosceles: "isosceles",
      equilateral: "equilateral",
      versatile: "versatile",
      rectangular: "rectangular",
      acteangle: "acteangle",
      obtuse: "obtuse",
    }

    setresultSin2("")
    valuesSinAngle = valuesSinAngle.map(Number)
    var triangleSinAngle = calculateSquareSinAngle(valuesSinAngle)
    var imageSinAngle = triangleImages[triangleSinAngle.type]
    var captionSinAngle = triangleCaptions[triangleSinAngle.type]
    setresultSin2(
      "s = " +
        triangleSinAngle.squareSinAngle +
        "; " +
        "Sides of the triangle: а = " +
        valuesSinAngle[0] +
        "; " +
        "b = " +
        triangleSinAngle.bSinAngle +
        "; " +
        "c = " +
        triangleSinAngle.cSinAngle +
        "; " +
        "Triangle angles in degrees: α = " +
        valuesSinAngle[1] +
        "; " +
        "β = " +
        valuesSinAngle[2] +
        "; " +
        "γ = " +
        triangleSinAngle.gammaSinAngle +
        "; " +
        "Triangle " +
        captionSinAngle
    )
    setresultImageSin2(imageSinAngle)
  }
  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals)
  }
  Math.radians = function (degrees) {
    return (degrees * Math.PI) / 180
  }

  Math.degrees = function (radians) {
    return (radians * 180) / Math.PI
  }

  function calculate(values) {
    var p = (values[0] + values[1] + values[2]) / 2
    var s = Math.sqrt(
      p * (p - values[0]) * (p - values[1]) * (p - values[2])
    ).toFixed(4)

    return {
      s: s,
      type: getType(values),
    }
  }

  function getType(values) {
    return values[0] === values[1] &&
      values[0] === values[2] &&
      values[1] === values[2]
      ? "equilateral"
      : values[0] === values[1] ||
        values[0] === values[2] ||
        values[1] === values[2]
      ? "isosceles"
      : "versatile"
  }

  function isNotExists(values) {
    return !(
      values[0] < values[1] + values[2] &&
      values[1] < values[0] + values[2] &&
      values[2] < values[0] + values[1]
    )
  }

  function validate(values) {
    var result = ""
    values.forEach(function (item) {
      if (item === "" || typeof item === "undefined") {
        result = "No values entered"
      }
      if (item && !/^\d+(?:[.](\d+))?$/.test(item)) {
        result = "Invalid value"
      }
    })
    return result
  }

  function calculateSquareCos(valuesCos) {
    var cCos = round(
      Math.sqrt(
        Math.pow(valuesCos[0], 2) +
          Math.pow(valuesCos[1], 2) -
          2 * valuesCos[0] * valuesCos[1] * Math.cos(Math.radians(valuesCos[2]))
      ),
      2
    )
    valuesCos.unshift(+cCos)
    var bettaCos = round(
      Math.degrees(
        Math.asin(
          (valuesCos[2] * Math.sin(Math.radians(valuesCos[3]))) / valuesCos[0]
        )
      ),
      2
    )
    valuesCos.push(+bettaCos)
    var alphaCos = round(180 - (valuesCos[3] + bettaCos), 2)
    valuesCos.push(+alphaCos)
    var perimeter = (valuesCos[0] + valuesCos[1] + valuesCos[2]) / 2
    var squareCos = Math.sqrt(
      perimeter *
        (perimeter - valuesCos[0]) *
        (perimeter - valuesCos[1]) *
        (perimeter - valuesCos[2])
    ).toFixed(4)
    return {
      bettaCos: bettaCos,
      alphaCos: alphaCos,
      cCos: cCos,
      squareCos: squareCos,
      newValuesCos: valuesCos,
      typeCos: getTypeTriangleCos(valuesCos),
    }
  }

  function getTypeTriangleCos(valuesCos) {
    return valuesCos[3] === 90 || valuesCos[4] === 90 || valuesCos[5] === 90
      ? "rectangular"
      : valuesCos[3] > 90 || valuesCos[4] > 90 || valuesCos[5] > 90
      ? "obtuse"
      : "acteangle"
  }

  function calculateSquareSinSide(valuesSinSide) {
    var D = round(
      (valuesSinSide[0] * Math.sin(Math.radians(valuesSinSide[2]))) /
        valuesSinSide[1],
      2
    )
    if (D === 1) {
      var alphaSinSide = 90
      valuesSinSide.push(+alphaSinSide)
      var gammaSinSide = round(180 - (valuesSinSide[2] + alphaSinSide), 2)
      valuesSinSide.push(+gammaSinSide)
      var cSinSide = round(
        (valuesSinSide[1] * Math.sin(Math.radians(valuesSinSide[4]))) /
          Math.sin(Math.radians(valuesSinSide[2])),
        2
      )
      valuesSinSide.push(+cSinSide)
      var perimeterSinSide =
        (valuesSinSide[0] + valuesSinSide[1] + valuesSinSide[5]) / 2
      var squareSinSide = Math.sqrt(
        perimeterSinSide *
          (perimeterSinSide - valuesSinSide[0]) *
          (perimeterSinSide - valuesSinSide[1]) *
          (perimeterSinSide - valuesSinSide[5])
      ).toFixed(4)
    } else {
      alphaSinSide = "error"
      valuesSinSide.push(alphaSinSide)
      gammaSinSide = "error"
      valuesSinSide.push(gammaSinSide)
      cSinSide = "error"
      valuesSinSide.push(+cSinSide)
      perimeterSinSide = "error"
      squareSinSide = "error"
    }

    return {
      alphaSinSide: alphaSinSide,
      gammaSinSide: gammaSinSide,
      cSinSide: cSinSide,
      squareSinSide: squareSinSide,
      type: getTypeTriangleSinSide(valuesSinSide),
    }
  }
  function getTypeTriangleSinSide(valuesSinSide) {
    return valuesSinSide[2] === 90 ||
      valuesSinSide[3] === 90 ||
      valuesSinSide[4] === 90
      ? "rectangular"
      : valuesSinSide[2] > 90 || valuesSinSide[3] > 90 || valuesSinSide[4] > 90
      ? "obtuse"
      : "acteangle"
  }

  function calculateSquareSinAngle(valuesSinAngle) {
    var bSinAngle = round(
      (valuesSinAngle[0] *
        Math.degrees(Math.sin(Math.radians(valuesSinAngle[2])))) /
        Math.degrees(Math.sin(Math.radians(valuesSinAngle[1]))),
      2
    )
    valuesSinAngle.push(bSinAngle)
    var gammaSinAngle = 180 - (valuesSinAngle[1] + valuesSinAngle[2])
    valuesSinAngle.push(gammaSinAngle)
    var cSinAngle = round(
      (valuesSinAngle[0] *
        Math.degrees(Math.sin(Math.radians(valuesSinAngle[4])))) /
        Math.degrees(Math.sin(Math.radians(valuesSinAngle[1]))),
      2
    )
    valuesSinAngle.push(cSinAngle)
    var perimeterSinAngle =
      (valuesSinAngle[0] + valuesSinAngle[3] + valuesSinAngle[5]) / 2
    var squareSinAngle = Math.sqrt(
      perimeterSinAngle *
        (perimeterSinAngle - valuesSinAngle[0]) *
        (perimeterSinAngle - valuesSinAngle[3]) *
        (perimeterSinAngle - valuesSinAngle[5])
    ).toFixed(4)
    return {
      bSinAngle: bSinAngle,
      gammaSinAngle: gammaSinAngle,
      cSinAngle: cSinAngle,
      squareSinAngle: squareSinAngle,
      type: getTypeTriangleSinAngle(valuesSinAngle),
    }
  }
  function getTypeTriangleSinAngle(valuesSinAngle) {
    return valuesSinAngle[1] === 90 ||
      valuesSinAngle[2] === 90 ||
      valuesSinAngle[4] === 90
      ? "rectangular"
      : valuesSinAngle[1] > 90 ||
        valuesSinAngle[2] > 90 ||
        valuesSinAngle[4] > 90
      ? "obtuse"
      : "acteangle"
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Triangle Calculator"
        description="Triangle Calculator, Simple Fast Triangle Calculator, Calculation by the cosine theorem (two sides and the angle between them), Calculation by the sine theorem (two sides and an angle opposite one of them) Calculation by the sine theorem (side and two angles)."
        keywords={[
          "triangle calculate, Calculation by the sine theorem , Calculation by the cosine theorem ,Triangle Calculator , Calculation by the cosine theorem (two sides and the angle between them), Calculation by the sine theorem (two sides and an angle opposite one of them) Calculation by the sine theorem (side and two angles), know name of triangle,know triangle type,triangle calculation.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Triangle calculation</Title>
        <SubTitle>Triangle calculation</SubTitle>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>a</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="a"
                  value={a}
                  onChange={aChange}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>b</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="b"
                  value={b}
                  onChange={bChange}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>c</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="c"
                  value={c}
                  onChange={cChange}
                />
              </Control>
            </Field>

            <Button
              type="button"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              value="Calculate"
              onClick={run}
            >
              Calculate
            </Button>
          </Column>
          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <img src={imageTopPage} alt="triangle names" width="250" />
              <div>{resultsDivP}</div>
            </FlexDiv>
          </Column>
        </Columns>

        <Columns>
          <Column>
            <h3>
              Calculation by the cosine theorem <br />
              (two sides and the angle between them)
            </h3>
            <Field>
              <Control>
                <StaticButton>a</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="a_cos"
                  value={aCos}
                  onChange={aCosChange}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>b</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="b_cos"
                  value={bCos}
                  onChange={bCosChange}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>γ</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="α_cos"
                  value={xCos}
                  onChange={xCosChange}
                />
              </Control>
            </Field>
            <Button
              type="button"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              value="Calculate by the cosine theorem"
              onClick={runCos}
            >
              Calculate by the cosine theorem
            </Button>
          </Column>

          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <img src={imageCos} alt="Triangle Calculation a,b, γ" />
            </FlexDiv>
          </Column>
        </Columns>

        <FlexDiv margin="auto" maxWidth="500px" className="mb-3">
          {imageresultCos ? (
            <img src={imageresultCos} alt="triangle name type" width="250" />
          ) : (
            ""
          )}
          <br />
          {resultCos}
        </FlexDiv>

        <Columns>
          <Column>
            <h3>
              Calculation by the sine theorem <br />
              (two sides and an angle opposite one of them)
            </h3>
            <Field>
              <Control>
                <StaticButton>a</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="a_sin_1"
                  value={aSin1}
                  onChange={aSin1Change}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>b</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="b_sin_1"
                  value={bSin1}
                  onChange={bSin1Change}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>β (angle against side b)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="β_sin"
                  value={xbSin1}
                  onChange={xbSin1Change}
                />
              </Control>
            </Field>
            <Button
              type="button"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              value="Calculate by the sine theorem"
              onClick={runSinSide}
            >
              Calculate by the sine theorem
            </Button>
          </Column>

          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <img src={imageSin} alt="Triangle Calculation a,b, γ" />
            </FlexDiv>
          </Column>
        </Columns>

        <FlexDiv margin="auto" maxWidth="500px" className="mb-3">
          {resultImageSin1 ? (
            <img src={resultImageSin1} alt="triangle type" width="250" />
          ) : (
            ""
          )}
          <br />
          {resultSin1}
        </FlexDiv>

        <Columns>
          <Column>
            <h3 className="subtitle mycolor">
              Calculation by the sine theorem
              <br /> (side and two angles)
            </h3>
            <Field>
              <Control>
                <StaticButton>a</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="a_sin_2"
                  value={aSin2}
                  onChange={aSin2Change}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>α(angle against known side)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="α_sin_2"
                  value={xSin2}
                  onChange={xSin2Change}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>β/γ</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="text"
                  name="β_sin_2"
                  value={bSin2}
                  onChange={bSin2Change}
                />
              </Control>
            </Field>
            <Button
              type="button"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              value="Calculate by the sine theorem"
              onClick={runSinAngle}
            >
              Calculate by the sine theorem
            </Button>
          </Column>

          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <img src={imageSin1} alt="Triangle Calculation a,b, γ" />
            </FlexDiv>
          </Column>
        </Columns>

        <FlexDiv margin="auto" maxWidth="500px" className="mb-3">
          {resultImageSin2 ? (
            <img src={resultImageSin2} alt="triangle type" width="250" />
          ) : (
            ""
          )}
          <br />
          {resultSin2}
        </FlexDiv>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default TriangleCalculation
